<script setup lang="ts">
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import SkrCheckbox from '@/components/SkrCheckbox.vue'

const { t } = useI18n()

const userStore = useUserStore()
const { email, firstName, lastName } = storeToRefs(userStore)
const businessStore = useBusinessStore()
const widgetStore = useWidgetStore()

const { $config } = useNuxtApp()

const emit = defineEmits<{
  (event: 'close'): void
}>()

const data = reactive({
  loading: false,
  formData: {
    freetext: '',
    option1: false,
    option2: false,
    option3: false,
    option4: false,
    option5: false,
    option6: false,
  },
})

const onProduction = computed((): boolean => {
  return $config.public.mode === 'production'
})
const isDisabled = computed((): boolean => {
  return !Object.values(data.formData).some(Boolean)
})

const { hubspotRepository } = useApi()

const sendDataToHubspot = async () => {
  data.loading = true
  const optionsFieldName = 'sb_trial___detailed_feedback___csat_transactional'
  await hubspotRepository.sendData(
    $config.public.hubspotPortalId,
    onProduction.value ? '4b0c09cd-2132-4404-a28d-71e2ceaec269' : '39224d84-ed81-4b65-96c2-4668d65b74a0',
    {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: email.value,
        },
        {
          name: 'firstname',
          value: firstName.value,
        },
        {
          name: 'lastname',
          value: lastName.value,
        },
        {
          name: '0-2/skribble_business_id',
          value: businessStore.id,
        },
        data.formData.option1 && {
          name: optionsFieldName,
          value: 'Another provider meets our needs better',
        },
        data.formData.option2 && {
          name: optionsFieldName,
          value: 'Switching to e-signing is difficult and tedious',
        },
        data.formData.option3 && {
          name: optionsFieldName,
          value: "Business plan isn't the right fir for our needs",
        },
        data.formData.option4 && {
          name: optionsFieldName,
          value: 'Missing features',
        },
        data.formData.option5 && {
          name: optionsFieldName,
          value: "Doesn't fit my budget",
        },
        data.formData.option6 && {
          name: optionsFieldName,
          value: 'Hard to use or find information',
        },
        data.formData.freetext && {
          name: onProduction.value
            ? 'sb_trial___general_customer_satisfaction___csat_transactional___free_text'
            : 'sb_trial___general_customer_satisfaction___csat_transactional',
          value: data.formData.freetext,
        },
      ].filter(Boolean),
    }
  )

  data.loading = false
  widgetStore.createSnackbar({
    message: t('snackbar.feedback.thank_you'),
  })

  emit('close')
}
</script>

<template>
  <base-dialog data-cy="withdrawDocDialog" max-width="640" :title="t('dialog.transactional.csat.title')">
    <div>{{ t('dialog.transactional.csat.text') }}</div>
    <skr-checkbox v-model="data.formData.option1" class="checkbox mt-8 mb-0 pb-0">
      <span class="checkbox__label" :class="`checkbox__label--${data.formData.option1 ? 'active' : 'inactive'}`">{{
        $t('dialog.transactional.csat.option_1.label')
      }}</span>
    </skr-checkbox>
    <skr-checkbox v-model="data.formData.option2" class="mt-6 mb-0 pt-0 pb-0">
      <span class="checkbox__label" :class="`checkbox__label--${data.formData.option2 ? 'active' : 'inactive'}`">{{
        $t('dialog.transactional.csat.option_2.label')
      }}</span>
    </skr-checkbox>
    <skr-checkbox v-model="data.formData.option3" class="mt-6 mb-0 pt-0 pb-0">
      <span class="checkbox__label" :class="`checkbox__label--${data.formData.option3 ? 'active' : 'inactive'}`">{{
        $t('dialog.transactional.csat.option_3.label')
      }}</span>
    </skr-checkbox>
    <skr-checkbox v-model="data.formData.option4" class="mt-6 mb-0 pt-0 pb-0">
      <span class="checkbox__label" :class="`checkbox__label--${data.formData.option4 ? 'active' : 'inactive'}`">{{
        $t('dialog.transactional.csat.option_4.label')
      }}</span>
    </skr-checkbox>
    <skr-checkbox v-model="data.formData.option5" class="mt-6 mb-0 pt-0 pb-0">
      <span class="checkbox__label" :class="`checkbox__label--${data.formData.option5 ? 'active' : 'inactive'}`">{{
        $t('dialog.transactional.csat.option_5.label')
      }}</span>
    </skr-checkbox>
    <skr-checkbox v-model="data.formData.option6" class="mt-6 mb-0 pt-0 pb-0">
      <span class="checkbox__label" :class="`checkbox__label--${data.formData.option6 ? 'active' : 'inactive'}`">{{
        $t('dialog.transactional.csat.option_6.label')
      }}</span>
    </skr-checkbox>

    <v-textarea
      v-model="data.formData.freetext"
      auto-grow
      class="mt-12"
      :label="$t('dialog.transactional.csat.freetext.label')"
      name="freetext"
      maxlength="250"
      rows="3"
      clearable
      clear-icon="custom:clear"
    />
    <template #actions>
      <v-card-actions fixed>
        <v-btn :disabled="data.loading" size="x-large" color="primary" variant="outlined" @click="emit('close')">
          {{ $t('global.skip') }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="data.loading || isDisabled"
          :loading="data.loading"
          size="x-large"
          color="primary"
          variant="elevated"
          @click="sendDataToHubspot"
        >
          {{ $t('global.submit_feedback') }}
        </v-btn>
      </v-card-actions>
    </template>
  </base-dialog>
</template>

<style lang="sass">
.checkbox
  &__label
    font-size: 1.25rem
    font-weight: bold
    &--inactive
      color: $c-skribbleu
</style>
